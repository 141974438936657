import { useEffect, useState } from 'react';
import { HeroImage } from '@geberit/gdds';
import { useInView } from 'react-intersection-observer';

// styled
import { StyledHeroImageThemedWrapper } from './hero-image-themed.styles';

// types
import type { HeroImageThemedProps } from './hero-image-themed.types';

// components
import { HeroImageThemedDefault } from './hero-image-themed-default';

// utils
import { useThemeName } from 'utils/hooks/use-theme';
import { classNameBuilder } from 'utils/classNameBuilder';
import { getIdsOfEmptyContentBoxes } from './hero-image-themed.utils';
import { useContent } from 'components/Page/page-hooks';

const DELAY_DEFAULT = 6;
const DELAY_INFINITY = 99999;

function HeroImageThemed(props: HeroImageThemedProps) {
  const {
    contentBoxPosition,
    isSeoConformCarousel,
    items,
    isSection,
    hideScrollIndicator,
    disableAnimation,
    disableSliderControls,
    wideLayout,
  } = props;
  const { ref: refSlider, inView } = useInView({
    threshold: 0,
  });

  const { page } = useContent();
  const themeName = useThemeName();

  const [navHeight, setNavHeight] = useState<number>(0);

  const [isForgroundVideoOpen, setIsForgroundVideoOpen] = useState<boolean>(false);

  const emptyContentBoxIds = getIdsOfEmptyContentBoxes({ items });

  useEffect(() => {
    const headerHeight = document.getElementById('page-header')?.offsetHeight;
    setNavHeight(headerHeight ?? 0);
  }, []);

  const isNordicsContentPage = page?.type === 'nordics_contentpage';

  useEffect(() => {
    if (isForgroundVideoOpen) return;

    window?.swiper?.autoplay?.run();
  }, [isForgroundVideoOpen]);

  if (themeName === 'DEFAULT') {
    return (
      <div ref={refSlider}>
        <HeroImageThemedDefault
          {...props}
          emptyContentBoxIds={emptyContentBoxIds}
          isNordicsContentPage={isNordicsContentPage}
          navHeight={navHeight}
          isForgroundVideoOpen={isForgroundVideoOpen}
          setIsForgroundVideoOpen={setIsForgroundVideoOpen}
          sliderDelay={isForgroundVideoOpen || !inView ? DELAY_INFINITY : DELAY_DEFAULT}
          isSection={isSection}
        />
      </div>
    );
  }

  return (
    <StyledHeroImageThemedWrapper
      className={classNameBuilder(
        'hero-image-themed',
        hideScrollIndicator && 'hide-scrollbar-indicator',
        disableAnimation && 'disable-animation',
        disableSliderControls && 'disable-slider-controls',
        wideLayout && 'wide-layout',
      )}
      emptyContentBoxIds={emptyContentBoxIds}
      isSlider={items.length > 1}
      ref={refSlider}
    >
      <HeroImage
        type={items.length > 1 ? 'b' : 'a'}
        items={items}
        previewId={isNordicsContentPage ? '#pt_heroTypeABItems' : undefined}
        previewIdExtractor={
          isNordicsContentPage
            ? (_, index) => {
                return `#${index}`;
              }
            : undefined
        }
        navigationHeight={navHeight}
        isSeoConformCarousel={isSeoConformCarousel}
        contentBoxPosition={contentBoxPosition}
      />
    </StyledHeroImageThemedWrapper>
  );
}

export { HeroImageThemed };
