import { useInView } from 'react-intersection-observer';

// styles
import { StyledHeroImageVideoPlayer } from './hero-image-video-player.styles';

// types
import type { HeroImageVideoPlayerProps } from './hero-image-video-player.types';

// components
import { VIDEO_PLAYER_TYPE, VideoPlayer } from 'components/ContentElementsGdds/VideoPlayer';

// utils
import { useIsMobile } from 'components/App/SizeProvider';
import { useUniqueId } from 'utils/hooks/use-unique-id';
import { useThemeName } from 'utils/hooks/use-theme';
import { classNameBuilder } from 'utils/classNameBuilder';
import { getContentBoxColor } from './hero-image-video-player.utils';

function HeroImageVideoPlayer(props: Readonly<HeroImageVideoPlayerProps>) {
  const { disableAnimation, video, wideLayout, isSection } = props;

  const isMobile = useIsMobile();

  const id = useUniqueId();
  const cssId = `player-container-${id}`;

  const { ref: refVideoPlayer, inView } = useInView({
    threshold: 0,
  });

  const themeName = useThemeName();

  if (!video) return null;

  let videoPreviewImage: string;

  if (isMobile) {
    videoPreviewImage = video.imageObjectMobile.url || video.video.videoPreviewImage.mobile;
  } else {
    videoPreviewImage = video.imageObject.url || video.video.videoPreviewImage.desktop;
  }

  const _colorInProps =
    video.textbox?.color ?? (video.colorVariant === 'light' ? 'white' : 'black');

  const _heading = video.textbox?.headline ?? video.headline ?? '';
  const _subheading = video.textbox?.subline ?? video.subline ?? '';
  const _link = video.textbox?.link ?? video.link ?? undefined;
  const _position = video.textbox?.position ?? 'top';

  const _color = getContentBoxColor({
    color: _colorInProps,
    themeName,
  });

  return (
    <StyledHeroImageVideoPlayer
      className={classNameBuilder('hero-image-video-player', wideLayout && 'wide-layout')}
      ref={refVideoPlayer}
      color={_color}
      wideLayout={wideLayout}
    >
      <VideoPlayer
        playerType={VIDEO_PLAYER_TYPE.BACKGROUND}
        video={video.video}
        inView={inView}
        loopType={video.loopType}
        heading={_heading}
        subheading={_subheading}
        button={_link}
        color={_color}
        fallbackImg={videoPreviewImage}
        title={video.pictureAlt}
        cssID={cssId}
        hasPreviewId={false}
        position={_position}
        animation={!disableAnimation}
        emphasize={video.emphasize}
        isSection={isSection}
      />
    </StyledHeroImageVideoPlayer>
  );
}

export { HeroImageVideoPlayer };
